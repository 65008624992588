import React, {useContext} from 'react';

import {REVIEWS, REVIEWS_SECTION} from 'core/constants.js';

import {
    HealthFundStoreContext,
    ParamStoreContext,
    PracticeStoreContextV2,
} from 'core/stores/RootStore.js';

import {ReviewV2} from '@HealthShareAU/hs-component-library';

const ReviewsSection = () => {
    const healthFundStore = useContext(HealthFundStoreContext);
    const paramStore = useContext(ParamStoreContext);
    const profileStore = useContext(PracticeStoreContextV2);
    const {ratings} = profileStore.practice;
    if (!ratings) {
        return null;
    }
    const colorTheme =
        paramStore.hasSkin && healthFundStore.healthFundThemeColor
            ? healthFundStore.healthFundThemeColor
            : null;

    const reviewRatings = {
        attentiveness: ratings?.attentiveness,
        condition_communication: ratings?.conditionCommunication,
        number_of_ratings: ratings?.numberOfRatings,
        rating: ratings?.rating,
        treatment_communication: ratings?.treatmentCommunication,
        would_recommend: ratings?.wouldRecommend,
    };

    return (
        <ReviewV2
            colorTheme={colorTheme}
            gaSelector={REVIEWS}
            gaObjectType={REVIEWS_SECTION}
            dataTestId={REVIEWS_SECTION}
            ratings={reviewRatings}
        />
    );
};

export default ReviewsSection;
