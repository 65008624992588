import {observer} from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';

import autobind from 'common/decorators/autobind.js';
import CustomBookingBtn from 'core/components/v2/CustomBookingBtn';
import CallNowBtn from 'core/components/v2/CallNowBtn';

import {
    ButtonV2,
    ChipV2,
    ConcatenatedTextV2,
    LinkV2,
    OverviewCardV2,
} from '@HealthShareAU/hs-component-library';

import {
    BP_FHIR,
    BOOK_NOW_BTN_CUSTOM_URL_TEST_ID,
    BOOK_NOW_BTN_BKG_PROD_PRIMARY,
    ZM,
    SHOW_REFERRAL_BTN,
    OVERVIEW_SECTION,
    REPORT_AN_ISSUE,
    PAGE_TYPE_PRACTICE_LOCATION,
    PAGE_TYPE_PRACTITIONER,
    PAGE_TYPE_PRACTICE_GROUP,
} from 'core/constants.js';

import {PracticeStoreContextV2} from 'core/stores/RootStore.js';
import {TELEHEALTH_ONLY, TELEHEALTH_HYBRID} from 'core/telehealth.js';

import {
    BPSecureMessageButtonV2,
    BPWriteReferralButtonV2,
    HSReferralButtonV2,
    MDWriteReferralButtonV2,
    ZMWriteReferralButtonV2,
} from 'professional/components/v2/ReferralButtonsV2.js';

import ButtonGroup, {BUTTON_TYPE} from 'core/components/ButtonGroup.js';

import {openLinkInNewTab, sessionStorageGetItem} from 'core/utils.js';
import TelehealthPill from 'core/components/v2/TelehealthPill';
import HicapsPill from 'core/components/v2/HicapsPill';
import OutOfPocketCostsPill from 'core/components/v2/OutOfPocketCostsPill';
import {RatingsV2} from '@HealthShareAU/hs-component-library';
import Modal from 'core/components/Modal.js';
import ReportModal from 'core/components/ReportModal.js';

export default
@observer
class PracticeHeaderV2 extends React.Component {
    static contextType = PracticeStoreContextV2;
    constructor(props) {
        super(props);

        this.headerRef = React.createRef();
        this.minHeight = 160;

        this.state = {
            aboveMinHeight: false,
            showReportModal: false,
        };
    }

    componentDidUpdate() {
        const {height} = this.headerRef.current.getBoundingClientRect();
        if (height > this.minHeight && !this.state.aboveMinHeight) {
            this.setState({aboveMinHeight: true});
        }
    }

    renderPhoneNumbers() {
        const {
            contactProps,
            header: {phones, name},
            rootStore: {
                paramStore: {hasSkin},
                healthFundStore: {healthFundThemeColor},
            },
        } = this.context;

        const colorTheme =
            hasSkin && healthFundThemeColor ? healthFundThemeColor : null;
        if (!phones || Object.keys(phones) === 0 || !phones.numbers) {
            return [];
        }
        return phones.numbers?.map((phone, index) => {
            const {description, number} = phone;

            return (
                <ChipV2
                    colorTheme={colorTheme}
                    customClass="phone"
                    icon="phone"
                    key={`phone-number${index}${
                        description ? `-${description}` : ''
                    }`}
                >
                    <CallNowBtn
                        colorTheme={colorTheme}
                        customTrigger={(onClick) => (
                            <ConcatenatedTextV2
                                colorTheme={colorTheme}
                                customClass="no-underline"
                                displayLink={`tel:${number}`}
                                expandText="Show phone number"
                                isExpanded={false}
                                length={8}
                                onConcatenation={onClick}
                                text={number}
                            />
                        )}
                        modalContent={{
                            ...contactProps,
                            colorTheme,
                            hasSkin,
                            number,
                        }}
                    />
                </ChipV2>
            );
        });
    }

    renderLanguages() {
        const {
            header: {languagesSpoken},
            rootStore: {
                paramStore: {hasSkin},
                healthFundStore: {healthFundThemeColor},
            },
        } = this.context;
        if (!languagesSpoken?.length) {
            return null;
        }

        let languageStr = 'Speaks English';
        if (languagesSpoken?.length === 1) {
            languageStr = `${languageStr} and ${languagesSpoken[0]}`;
        } else if (languagesSpoken?.length >= 2) {
            const newLangArray = languagesSpoken.slice(0, -1);
            newLangArray.push(
                `and ${languagesSpoken[languagesSpoken.length - 1]}`,
            );
            languageStr = `${languageStr}, ${newLangArray.join(', ')}`;
        }
        return (
            <ChipV2
                colorTheme={
                    hasSkin && healthFundThemeColor
                        ? healthFundThemeColor
                        : null
                }
                customClass="language"
                dataTestId="lanuages"
                icon="messageCircle"
                key="language"
            >
                {languageStr}
            </ChipV2>
        );
    }

    renderAddress() {
        const {
            header: {streetAddress, locality},
            practice,
            rootStore: {
                paramStore: {hasSkin},
                healthFundStore: {healthFundThemeColor},
            },
        } = this.context;

        if (
            (streetAddress || locality) &&
            practice?.offersTelehealth !== TELEHEALTH_ONLY
        ) {
            const trimmedStreetAddress = streetAddress.trim();
            return (
                <ChipV2
                    colorTheme={
                        hasSkin && healthFundThemeColor
                            ? healthFundThemeColor
                            : null
                    }
                    customClass="address"
                    icon="markerPin"
                    key="address"
                >
                    <LinkV2
                        dataTestId="address"
                        gaObjectType="address-link"
                        gaSelector="overview-section"
                        href={'#location'}
                        target="_self"
                        text={`${trimmedStreetAddress}${
                            trimmedStreetAddress
                                ? locality && ', ' + locality
                                : locality
                        }`}
                    />
                </ChipV2>
            );
        } else {
            return null;
        }
    }

    getButtons() {
        const {
            practice,
            rootStore: {
                healthFundStore: {isHealthFundBookingsEnabled},
                paramStore: {hasSkin, isReferrals},
            },
            practice: {zmData},
        } = this.context;

        const {referralToPractice} = practice;
        const buttons = [];
        const showReferralBtn =
            sessionStorageGetItem(SHOW_REFERRAL_BTN) === 'true';

        if (
            isHealthFundBookingsEnabled &&
            !hasSkin &&
            practice.customBookingDataUrl
        ) {
            buttons.push({
                buttonType: BUTTON_TYPE.INTEGRATED_BOOK_NOW,
                component: (
                    <CustomBookingBtn
                        gaObjectType="practice location"
                        customBookingDataUrl={practice.customBookingDataUrl}
                    />
                ),
            });
        } else if (
            (zmData && !showReferralBtn) ||
            (isReferrals && referralToPractice)
        ) {
            buttons.push({
                buttonType: BUTTON_TYPE.REFERRAL,
                component: this.renderHsReferralButton(),
            });
        }

        return buttons;
    }

    getReferralData(data) {
        const {offersTelehealth} = this.context.practice;
        const referralData = {...data};
        if (offersTelehealth === TELEHEALTH_ONLY) {
            // remove address data if pure telehealth
            // bp data
            delete referralData.AddressLine1;
            delete referralData.AddressLine2;
            delete referralData.Postcode;
            referralData.Suburb = 'Telehealth';

            //md data
            delete referralData.City;
            delete referralData.PCity;
            delete referralData.PPostcode;
            delete referralData.PStreetLine1;
            delete referralData.PStreetLine2;
            delete referralData.PStreetLine3;
            delete referralData.StreetLine1;
            delete referralData.StreetLine2;
            delete referralData.StreetLine3;
        }
        return referralData;
    }

    getReferralButtons() {
        const {
            rootStore: {
                paramStore: {client},
            },
            practice: {bpData, id, mdData, zmData},
        } = this.context;
        const buttons = [];
        const showReferralBtn =
            sessionStorageGetItem(SHOW_REFERRAL_BTN) === 'true';
        if (bpData) {
            if (client === BP_FHIR) {
                buttons.push({
                    buttonType: BUTTON_TYPE.SECURE_MESSAGE,
                    component: (
                        <BPSecureMessageButtonV2
                            eventData={{
                                'practice_location_id': id,
                            }}
                            fhirData={this.getReferralData(bpData)}
                        />
                    ),
                });
            } else {
                buttons.push({
                    buttonType: BUTTON_TYPE.REFERRAL,
                    component: (
                        <BPWriteReferralButtonV2
                            bpData={this.getReferralData(bpData)}
                            eventData={{
                                'practice_location_id': id,
                            }}
                        />
                    ),
                });
            }
        } else if (mdData) {
            buttons.push({
                buttonType: BUTTON_TYPE.REFERRAL,
                component: (
                    <MDWriteReferralButtonV2
                        eventData={{
                            'practice_location_id': id,
                        }}
                        mdData={this.getReferralData(mdData)}
                    />
                ),
            });
        } else if (zmData && client == ZM && showReferralBtn) {
            buttons.push({
                buttonType: BUTTON_TYPE.REFERRAL,
                component: (
                    <ZMWriteReferralButtonV2
                        eventData={{'practice_location_id': id}}
                        zmData={zmData}
                    />
                ),
            });
        }

        return buttons;
    }

    renderHsReferralButton() {
        const {
            getCopyData,
            practice: {id},
            rootStore: {
                paramStore: {hasSkin},
                healthFundStore: {healthFundThemeColor},
            },
        } = this.context;
        return (
            <HSReferralButtonV2
                eventData={{
                    eventName: 'copyPracticeLocationDetails',
                    data: {
                        'practice_location': id,
                    },
                }}
                colorTheme={
                    hasSkin && healthFundThemeColor
                        ? healthFundThemeColor
                        : null
                }
                getCopyData={getCopyData}
                gaObjectType={'overview-section'}
            />
        );
    }

    get offersTelehealth() {
        const {
            isPracticeGroup,
            practice: {locations, offersTelehealth},
        } = this.context;
        if (isPracticeGroup) {
            const telehealthPractices = locations?.filter(
                (p) =>
                    p.offersTelehealth === TELEHEALTH_ONLY ||
                    p.offersTelehealth === TELEHEALTH_HYBRID,
            );
            return telehealthPractices?.length > 0;
        }
        return (
            offersTelehealth ||
            offersTelehealth === TELEHEALTH_ONLY ||
            offersTelehealth === TELEHEALTH_HYBRID
        );
    }

    get offersHicaps() {
        const {
            practice: {locations, offersHicaps},
        } = this.context;
        if (!!locations) {
            return locations?.filter((loc) => loc.offersHicaps)?.length > 0;
        }
        return offersHicaps;
    }

    get specialties() {
        const {specialties} = this.context;

        const specialtiesList = specialties?.map((obj) => obj?.displayName);

        const result = specialtiesList?.join(', ');

        if (result) {
            return result;
        }

        return null;
    }

    get taglineHeader() {
        const {
            rootStore: {
                paramStore: {hasSkin},
            },
            practice: {tagline},
        } = this.context;

        if (tagline && !hasSkin) {
            return tagline;
        }
        return null;
    }

    get taglineDescription() {
        const {
            rootStore: {
                paramStore: {hasSkin},
            },
            practice: {tagline2},
        } = this.context;

        if (!hasSkin) {
            if (tagline2) {
                return tagline2;
            }
        }

        return null;
    }

    renderButtonGroup() {
        const {
            practice: {bpData, mdData, zmData},
        } = this.context;
        const showReferralBtn =
            sessionStorageGetItem(SHOW_REFERRAL_BTN) === 'true';
        const buttons =
            bpData || mdData || (zmData && showReferralBtn)
                ? this.getReferralButtons()
                : this.getButtons();

        return <ButtonGroup buttons={buttons} />;
    }

    renderRatings() {
        const {
            rootStore: {
                paramStore: {hasSkin},
                healthFundStore: {healthFundThemeColor},
            },
            practice: {ratings},
        } = this.context;

        if (ratings?.numberOfRatings && ratings?.rating) {
            return (
                <RatingsV2
                    key="ratings"
                    //TODO it needs to be updated to reviews section later
                    url={'#reviews'}
                    numRatings={ratings?.numberOfRatings}
                    rating={ratings?.rating}
                    colorTheme={
                        hasSkin && healthFundThemeColor
                            ? healthFundThemeColor
                            : null
                    }
                    allowSkin
                />
            );
        }

        return null;
    }

    renderGender() {
        const {
            rootStore: {
                paramStore: {hasSkin},
                healthFundStore: {healthFundThemeColor},
            },
            practice: {genderName},
        } = this.context;

        if (genderName && genderName !== 'Unknown' && genderName !== 'Other') {
            return (
                <ChipV2
                    key="gender"
                    icon="user"
                    colorTheme={
                        hasSkin && healthFundThemeColor
                            ? healthFundThemeColor
                            : null
                    }
                >{`${genderName} Practitioner`}</ChipV2>
            );
        }

        return null;
    }

    @autobind
    closeReportModal() {
        this.setState({showReportModal: false});
    }

    @autobind
    showReportModal() {
        this.setState({showReportModal: true});
    }

    renderReportModal() {
        const {
            userId,
            isPracticeGroup,
            practice: {displayName, name},
            rootStore: {
                paramStore: {isClient},
            },
        } = this.context;
        const activeElement = document.activeElement;
        const pageQuerySelector = {
            [PAGE_TYPE_PRACTITIONER]: '#professional-profile',
            [PAGE_TYPE_PRACTICE_LOCATION]: '#practice-location',
            [PAGE_TYPE_PRACTICE_GROUP]: '#practice-group',
        };
        const modalCategories = {
            [PAGE_TYPE_PRACTITIONER]: ['Address', 'Phone number', 'Other'],
            [PAGE_TYPE_PRACTICE_LOCATION]: [
                'Address',
                'Phone number',
                'Practitioners',
                'Other',
            ],
            [PAGE_TYPE_PRACTICE_GROUP]: [
                'Address',
                'Phone number',
                'Practitioners',
                'Other',
            ],
        };
        const pageType = !!userId
            ? PAGE_TYPE_PRACTITIONER
            : isPracticeGroup
            ? PAGE_TYPE_PRACTICE_GROUP
            : PAGE_TYPE_PRACTICE_LOCATION;
        return ReactDOM.createPortal(
            <Modal
                closeModal={this.closeReportModal}
                initElement={activeElement}
                parentElement={activeElement.parentElement}
            >
                <ReportModal
                    availableCategories={modalCategories[pageType]}
                    closeModal={this.closeReportModal}
                    displayName={!!userId ? displayName : name}
                    isClient={!!isClient}
                    pageType={pageType}
                />
            </Modal>,
            document.querySelector(pageQuerySelector[pageType]),
        );
    }

    renderReportIssueBtn(colorTheme) {
        return (
            <div className="report-issue">
                <ButtonV2
                    colorTheme={colorTheme}
                    onClick={this.showReportModal}
                    text="Report Issue"
                    icon="flag"
                    type="tertiary"
                    gaObjectType={OVERVIEW_SECTION}
                    gaSelector={REPORT_AN_ISSUE}
                    dataTestId={REPORT_AN_ISSUE}
                />
                {this.state.showReportModal && this.renderReportModal()}
            </div>
        );
    }

    render() {
        const {practiceLoaded} = this.context;
        if (!practiceLoaded) {
            return null;
        }

        const {
            avatarPlaceholderIcon,
            header: {name},
            rootStore: {
                paramStore: {hasSkin, isStaff},
                healthFundStore: {healthFundThemeColor},
            },
            practice: {avatar, enhanced: isEnhanced, isRetired},
            loadFees,
        } = this.context;

        const colorTheme =
            hasSkin && healthFundThemeColor ? healthFundThemeColor : null;

        const overviewCardProps = {
            alt: name,
            avatarIconType: avatarPlaceholderIcon,
            avatarSource: avatar,
            colorTheme,
            componentRef: this.headerRef,
            dataTestId: 'overview',
            extraModule: this.renderButtonGroup(),
            reportIssue: this.renderReportIssueBtn(colorTheme),
            heading: name,
            subHeading: this.specialties,
            isEnhanced: isEnhanced && isStaff,
            isRetired,
            chips: [
                this.renderRatings(),
                this.renderGender(),
                this.renderAddress(),
                this.renderLanguages(),
                ...this.renderPhoneNumbers(),
            ].filter((chip) => !!chip),
            pills: [
                this.offersTelehealth && (
                    <TelehealthPill colorTheme={colorTheme} />
                ),
                this.offersHicaps && <HicapsPill colorTheme={colorTheme} />,
                loadFees && (
                    <OutOfPocketCostsPill
                        colorTheme={colorTheme || healthFundThemeColor}
                        gaObjectType="overview-section"
                    />
                ),
            ].filter((pill) => !!pill),
            taglineDescription: this.taglineDescription,
            taglineHeader: this.taglineHeader,
        };
        return <OverviewCardV2 {...overviewCardProps} />;
    }
}
