import React from 'react';
import {renderTemplate} from 'template.js';
import FeeInfo from 'professional/components/v2/FeeInfo.js';

const FeesInfo = ({
    colorTheme,
    consultationFeeMessage,
    procedureFeeMessage,
    profileName,
}) => {
    return (
        <div className="hs-fees-info-v2">
            <FeeInfo
                colorTheme={colorTheme}
                header={`Consultation fees for ${profileName}`}
                body={renderTemplate(consultationFeeMessage, {
                    name: profileName,
                })}
            />
            <FeeInfo
                colorTheme={colorTheme}
                header={`Procedure fees for ${profileName}`}
                body={renderTemplate(procedureFeeMessage, {
                    name: profileName,
                })}
            />
        </div>
    );
};
export default FeesInfo;
