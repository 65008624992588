import React from 'react';
import {observer} from 'mobx-react';

import {
    ADDRESS_LINK,
    CALL_NOW_BTN_SECONDARY,
    DIRECTORY_SEARCH_RESULT_CARD_LINK,
    PROFILE_CARD,
    PROFILE_CONTACT_TYPE,
    SEE_OUT_OF_POCKET_COSTS,
} from 'core/constants.js';
import {PracticeStoreContextV2} from 'core/stores/RootStore.js';

import {CardV2} from '@HealthShareAU/hs-component-library';
@observer
class PracticeListV2 extends React.Component {
    static contextType = PracticeStoreContextV2;

    constructor(props) {
        super(props);
        this.state = {
            callNowModalOpen: false,
            selectedItem: {},
        };
    }

    getFees() {
        const {loadFees} = this.context;
        if (loadFees) {
            return {
                feesUrl: `#fees`,
                gaObjectType: PROFILE_CARD,
                gaSelector: SEE_OUT_OF_POCKET_COSTS,
            };
        }
        return null;
    }

    render() {
        const {
            practice,
            renderCallNowModal,
            rootStore: {
                paramStore: {hasSkin},
                healthFundStore: {healthFundThemeColor},
            },
            showCallNow,
            loadFees,
            profileCardCTA,
        } = this.context;

        return (
            <>
                <ul>
                    {practice.locations?.map((location, idx) => {
                        const {
                            address,
                            avatar,
                            locality: {
                                name: localityName,
                                postCode,
                                state: {name: stateName},
                            },
                            name,
                            offersHicaps,
                            offersTelehealth,
                            practiceUrl,
                            phoneNumbers,
                        } = location;

                        const PPnumber = phoneNumbers[0]?.formattedNumber;
                        return (
                            <CardV2
                                alt={name}
                                addressChip={{
                                    addressText: address,
                                    gaObjectType: PROFILE_CARD,
                                    gaSelector: ADDRESS_LINK,
                                }}
                                colorTheme={
                                    hasSkin && healthFundThemeColor
                                        ? healthFundThemeColor
                                        : null
                                }
                                asElement="li"
                                avatar={{
                                    src: avatar?.includes('empty_avatar')
                                        ? ''
                                        : avatar,
                                    icon: 'building',
                                }}
                                button={profileCardCTA(
                                    location,
                                    PROFILE_CARD,
                                    PROFILE_CONTACT_TYPE,
                                )}
                                dataTestId={PROFILE_CARD}
                                gaObjectType={PROFILE_CARD}
                                gaSelector={DIRECTORY_SEARCH_RESULT_CARD_LINK}
                                header={`${localityName} ${stateName} ${postCode}`}
                                href={practiceUrl}
                                key={idx}
                                offersHicaps={offersHicaps}
                                offersTelehealth={offersTelehealth}
                                subheader={name}
                                disabled={!practiceUrl}
                                phoneChip={{
                                    phone: PPnumber,
                                    gaObjectType: PROFILE_CARD,
                                    gaSelector: CALL_NOW_BTN_SECONDARY,
                                }}
                                onPhoneClick={() =>
                                    showCallNow({
                                        ...location,
                                        type: PROFILE_CONTACT_TYPE,
                                    })
                                }
                                feesChip={loadFees && this.getFees()}
                                feesColor={healthFundThemeColor}
                            />
                        );
                    })}
                </ul>
                {renderCallNowModal()}
            </>
        );
    }
}

export default PracticeListV2;
