import React from 'react';
import {observer} from 'mobx-react';

import autobind from 'common/decorators/autobind.js';

import {FEES_SECTION} from 'core/constants.js';
import {PracticeStoreContextV2} from 'core/stores/RootStore.js';
import HealthFundPickerV2 from 'core/components/v2/HealthFundPicker.js';

import FundInfo from 'professional/components/FundInfo.js';
import FeesInfo from 'professional/components/v2/FeesInfo.js';

import {
    IconV2,
    SectionContentWrapperV2,
    SectionV2,
} from '@HealthShareAU/hs-component-library';

export default
@observer
class FeesSection extends React.Component {
    static contextType = PracticeStoreContextV2;
    @autobind
    async HFselector(e) {
        const {selectHealthFundHandler} = this.context;
        selectHealthFundHandler(e);
    }
    render() {
        const {
            rootStore: {
                healthFundStore: {healthFund, healthFundThemeColor},
                paramStore: {hasSkin, isHfWidget, isReferrals, showParam},
            },
            customTextLoaded,
            customTextConsultationFee,
            customTextProcedureFee,
            fundInfo,
            isSpecialist,
            practice: {id: profileId, displayName: profileName},
        } = this.context;
        const colorTheme =
            hasSkin && healthFundThemeColor ? healthFundThemeColor : null;
        return (
            <SectionV2
                colorTheme={colorTheme}
                customClass="hs-fees-section-v2"
                header={
                    hasSkin
                        ? 'See how we can cover you'
                        : 'See how your health fund can cover you'
                }
                headerIcon={
                    <IconV2
                        colorTheme={colorTheme}
                        customClass="hs-header-title-v2--icon"
                        name="shield"
                        size="size-md"
                    />
                }
                sectionType="regular"
            >
                <SectionContentWrapperV2>
                    <HealthFundPickerV2
                        containerClassName="no-border left-align"
                        customClass="hs-fees-section-v2--health-fund-selector"
                        gaObjectType={FEES_SECTION}
                        handleSelectHealthFund={(e) => this.HFselector(e)}
                        initialOptionText={
                            "I don't have private health insurance"
                        }
                        showSelector={!isHfWidget}
                        stopAutoScroll={true}
                        profileId={profileId}
                    />
                    {!!healthFund?.id && (
                        <FundInfo
                            colorTheme={colorTheme}
                            gaObjectType={FEES_SECTION}
                            healthFund={healthFund}
                            info={fundInfo}
                            isReferrals={isReferrals}
                            isSpecialist={isSpecialist}
                            profileId={profileId}
                            profileName={profileName}
                            show={showParam}
                            version={2}
                        />
                    )}
                    {customTextLoaded && !!healthFund?.id && isSpecialist && (
                        <FeesInfo
                            colorTheme={colorTheme}
                            consultationFeeMessage={customTextConsultationFee}
                            procedureFeeMessage={customTextProcedureFee}
                            profileName={profileName}
                        />
                    )}
                </SectionContentWrapperV2>
            </SectionV2>
        );
    }
}
