import React from 'react';
import {observer} from 'mobx-react';

import {PracticeStoreContextV2} from 'core/stores/RootStore.js';
import RelatedInfoV2 from 'core/components/v2/RelatedInfoV2.js';

export default
@observer
class PracticeRelatedInfoV2 extends React.Component {
    static contextType = PracticeStoreContextV2;

    render() {
        const {
            practice,
            relatedFactsheets,
            practiceRelatedContent,
            practice: {registrationNumbers},
            rootStore: {
                paramStore: {skin},
            },
            userId,
        } = this.context;
        let renderRelatedInfo = false;
        // If in UserProfile and no registrationNumbers, existing relatedFactsheets and skinned, do not load the tab.
        if (!!userId) {
            if (!!registrationNumbers) {
                renderRelatedInfo = true;
            } else if (relatedFactsheets?.length > 0 && !skin) {
                renderRelatedInfo = true;
            }
            // As PracticeLocation doesn't have registrationNumbers, we only load the tab if not skinned and have documents
        } else if (!!practiceRelatedContent || practice.files?.length > 0) {
            if (practice.files?.length > 0) {
                renderRelatedInfo = true;
            } else if (!skin && !!practiceRelatedContent) {
                renderRelatedInfo = true;
            }
        }

        /* Factsheets are loaded differently for UserProfile and PracticeLocation page:
         * UserProfile -> context.relatedFactsheets
         * PracticeLocation -> context.practiceRelatedContent.relatedFactsheets
         */
        const factsheets = !!userId
            ? relatedFactsheets
            : practiceRelatedContent?.relatedFactsheets;
        return renderRelatedInfo ? (
            <RelatedInfoV2
                files={practice.files ? practice.files : null}
                factsheets={!skin ? factsheets : null}
                registrationNumbers={
                    !!registrationNumbers ? registrationNumbers : null
                }
                name={practice.name || practice.displayName}
            />
        ) : null;
    }
}
