import React from 'react';
import {observer} from 'mobx-react';

import HicapsBadge from 'core/components/HicapsBadge.js';
import {PracticeStoreContextV2} from 'core/stores/RootStore.js';
import LocationList from 'practices/components/LocationList.js';
import PracticeFAQ from 'practices/components/v2/PracticeFAQV2.js';
import PracticeRelatedInfoV2 from 'practices/components/v2/PracticeRelatedInfoV2.js';
import PractitionerList from 'practices/components/v2/PractitionerListV2.js';

import AboutSection from 'core/components/AboutSection.js';
import BookingSection from 'core/components/BookingSection.js';
import GalleriesSection from 'core/components/GalleriesSection.js';
import LocationSection from 'core/components/LocationSection.js';
import QuestionsAndAnswersSection from 'core/components/QuestionsAndAnswersSection.js';
import ReviewsSection from 'professional/components/v2/ReviewsSection.js';
import FeesSection from 'professional/components/v2/FeesSection.js';

import {NavigationSectionV2} from '@HealthShareAU/hs-component-library';

@observer
export default class PracticeContentV2 extends React.Component {
    static contextType = PracticeStoreContextV2;
    tabPanels = {
        LocationList,
        PracticeRelatedInfoV2,
        PracticeFAQ,
        PractitionerList,
        BookingSection,
        GalleriesSection,
        LocationSection,
        QuestionsAndAnswersSection,
        AboutSection,
        ReviewsSection,
        FeesSection,
    };

    renderHicaps() {
        const {activeTab, practice, isPracticeGroup} = this.context;

        const isLocationTab = activeTab?.hash === 'locations';
        if (activeTab === null || !isPracticeGroup || !isLocationTab) {
            return null;
        }

        const offersHicaps = practice.locations?.find(
            (prac) => prac.offersHicaps,
        );
        if (offersHicaps) {
            return (
                <div>
                    <HicapsBadge />
                </div>
            );
        }

        return null;
    }

    renderComponent() {
        const {
            activeTab,
            displayTabsOnPage,
            practiceTabs,
            rootStore: {
                healthFundStore: {healthFund},
                paramStore: {hasSkin},
            },
        } = this.context;

        const newTabs = practiceTabs?.map((i) => {
            const Component = this.tabPanels[i?.component];
            if (Component) {
                i.component = <Component />;
            }
            return i;
        });

        const props = {
            tabs: newTabs,
        };

        if (healthFund?.colorBrand && hasSkin) {
            const themeColor = `#${healthFund?.colorBrand}`;
            props.colorTheme = themeColor;
        }

        if (newTabs && newTabs?.length > 0) {
            return <NavigationSectionV2 {...props} />;
        } else {
            return null;
        }
    }

    render() {
        const {practiceLoaded} = this.context;
        if (!practiceLoaded) {
            return null;
        }
        return (
            <section className="main-content" role="tabpanel">
                {this.renderHicaps()}
                {this.renderComponent()}
            </section>
        );
    }
}
