import React from 'react';
import {observer} from 'mobx-react';

import {
    DIRECTORY_SEARCH_RESULT_CARD_LINK,
    PRACTICE_LOC_CONTACT_TYPE,
    PROFILE_CARD,
    SEE_OUT_OF_POCKET_COSTS,
} from 'core/constants.js';
import {PracticeStoreContextV2} from 'core/stores/RootStore.js';

import {CardV2} from '@HealthShareAU/hs-component-library';
import {NotificationV2} from '@HealthShareAU/hs-component-library';
@observer
class PractitionerListV2 extends React.Component {
    static contextType = PracticeStoreContextV2;

    participationBannerText(healthFund, hasParticipation = true) {
        const hfNameStartsWithVowel = !!healthFund.name[0]
            .toLowerCase()
            .match(/a|e|i|o|u/);
        return (
            <>
                The following practitioners may{' '}
                {!hasParticipation ? 'not ' : ''}participate in{' '}
                {hfNameStartsWithVowel ? 'an' : 'a'}{' '}
                <strong>{healthFund.name}</strong> gap scheme.
            </>
        );
    }

    renderNonParticipationBanner(healthFund) {
        return (
            <NotificationV2
                dataTestId="health-fund-non-participation-banner"
                type="error"
                text={this.participationBannerText(healthFund, false)}
            />
        );
    }

    renderParticipationBanner(healthFund) {
        const participatingStyle = {
            color: `#${healthFund.colorBrand}`,
        };
        const notifImg = <img src={healthFund.avatar} />;
        return (
            <NotificationV2
                colorTheme={participatingStyle}
                dataTestId="health-fund-participation-banner"
                displayImage={notifImg}
                text={this.participationBannerText(healthFund)}
            />
        );
    }

    renderCard(practitioner, idx, additionalProps) {
        const {profileCardCTA, practice} = this.context;
        const {
            avatar,
            displayName,
            offersTelehealth,
            interestsDescription,
            profileUrl,
            specialties,
        } = practitioner;
        const specialtiesStr = specialties
            .map((specialty) => specialty.name)
            .join(', ');
        const profileCardProps = {
            ...additionalProps,
            alt:displayName,
            asElement: 'li',
            avatar: {
                src: avatar.includes('empty_avatar') ? '' : avatar,
            },
            button: profileCardCTA(
                practitioner,
                PROFILE_CARD,
                PRACTICE_LOC_CONTACT_TYPE,
            ),
            dataTestId: PROFILE_CARD,
            gaObjectType: PROFILE_CARD,
            gaSelector: DIRECTORY_SEARCH_RESULT_CARD_LINK,
            header: displayName,
            href: profileUrl,
            key: idx,
            offersHicaps: practice.offersHicaps,
            offersTelehealth: offersTelehealth,
            specialInterests: interestsDescription,
            subheader: specialtiesStr,
        };
        return <CardV2 {...profileCardProps} />;
    }
    render() {
        const {
            hasPractitioners,
            gapSchemePractitioners,
            nonGapSchemePractitioners,
            practice,
            rootStore: {
                healthFundStore: {healthFund},
                paramStore: {hasSkin},
            },
        } = this.context;
        if (!hasPractitioners) {
            return null;
        }

        if (healthFund?.id) {
            const participatingStyle = {
                color: `#${healthFund.colorBrand}`,
            };
            const colorTheme = hasSkin ? participatingStyle : null;
            return (
                <>
                    {!!gapSchemePractitioners?.length && (
                        <>
                            {this.renderParticipationBanner(healthFund)}
                            <ul>
                                {gapSchemePractitioners.map(
                                    (practitioner, idx) =>
                                        this.renderCard(practitioner, idx, {
                                            colorTheme,
                                            feesColor: participatingStyle,
                                            feesChip: {
                                                feesUrl: `${practitioner.profileUrl}?health_fund=${healthFund.id}#fees`,
                                                gaObjectType: PROFILE_CARD,
                                                gaSelector: SEE_OUT_OF_POCKET_COSTS,
                                            },
                                        }),
                                )}
                            </ul>
                        </>
                    )}
                    {!!nonGapSchemePractitioners?.length && (
                        <>
                            {this.renderNonParticipationBanner(healthFund)}
                            <ul>
                                {nonGapSchemePractitioners.map(
                                    (practitioner, idx) =>
                                        this.renderCard(practitioner, idx, {
                                            colorTheme,
                                        }),
                                )}
                            </ul>
                        </>
                    )}
                </>
            );
        } else {
            return (
                <ul>
                    {practice.practitioners?.map((practitioner, idx) =>
                        this.renderCard(practitioner, idx),
                    )}
                </ul>
            );
        }
    }
}

export default PractitionerListV2;
