import React from 'react';
import {
    ConcatenatedTextV2,
    HeaderTitleV2,
} from '@HealthShareAU/hs-component-library';

const FeeInfo = ({colorTheme, header, body}) => {
    return (
        <div className="hs-fee-info-v2">
            <HeaderTitleV2
                colorTheme={colorTheme}
                customClass="header"
                headerType="submain"
                heading={header}
            />
            <ConcatenatedTextV2
                customClass="hsv2-allow-html-content"
                colorTheme={colorTheme}
                defaultEllipsis
                isCollapsible
                isShowIcon
                length={200}
                text={body}
            />
        </div>
    );
};
export default FeeInfo;
